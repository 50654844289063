.login-logo{
    width: 20%;
}
.image-text{
    text-align: center;
}
.background-img{
    background-image: url('/public/images/backgroundriver.png') !important;
    min-width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: inherit;
}
.card-css{
    width: 35%;
    /* display: flex; */
    margin: auto;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
}