.popup-content{
    height: auto !important;
    width: 70% !important;
    left: 396.256px !important;
}

.link-cell {
    display: grid;
  }

  .star{
    color: red;
  }
  
@media (min-width: 769px) and (max-width:1025px) {
    .popup-content{
       
        left: 250px !important;
    }
}
@media (min-width: 319px) and (max-width:768px) {
    .popup-content{
        width: 25rem !important;
        left: 35px !important;
    }
}