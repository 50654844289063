.card-table{
    border: 1px solid #dbdfea;
    margin: 0 35px;
}
.banner-tab{
    border: 2px solid !important;
    border-radius: 8px !important;
    padding: 15px 25px !important;
}
.components-preview .card-preview > .card-inner{
    padding: 2rem 3rem !important;
}

.banner-tab2{
    border: 2px solid !important;
    border-radius: 8px !important;
    padding: 15px 25px !important; 
    margin-bottom: 2rem;
}

.margin-muthai{
    margin-bottom: 2rem;
}

.form-control-wrap{
    margin-bottom: 1rem;
}

.banner-tab h3{
    margin-bottom: 2rem;
}

.banner-tab2 h3{
    margin-bottom: 2rem;
}

.form-control, .rdl-filter, .dual-listbox .dual-listbox__search, div.dataTables_wrapper div.dataTables_filter input{
    border: 1px solid #767676 !important;
}

.password-input {
    position: relative;
  }
  
  .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  