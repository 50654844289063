// Dots
$dot-size:
    "xs"    4px,
    "sm"    6px,
    "md"    8px,
    "rg"    10px,
    "lg"    12px,
    "xl"    16px;

.dot{
    position: relative;
    display: inline-block;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    &-label{
        &:after{
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            border-radius: 50%;
            background: $white;
            content: '';
            transform: scale(.65);
        }
    }
    &.sq{
        border-radius: 3px;
        &:after{
            border-radius: 3px;
        }
    }
}
@each $name, $value in $dot-size {
    .dot-#{$name}{
        height: $value;
        width: $value;
    }
}
@each $name, $value in $theme-colors {
    .dot-#{$name}{
        background: $value;
    }
}