.timeline{
    color: $base-light;
    line-height: 1.3;
    + .timeline, &-list + .timeline-head {
        margin-top: 1.75rem;
    }
    &-head{
        font-size: $fx-sz-14;
        color: $base-light;
        margin-bottom: 1rem;
    }
    &-item{
        position: relative;
        display: flex;
        align-items: flex-start;
        &:not(:last-child){
            padding-bottom: 1.5rem;
            &:before{
                position: absolute;
                height: calc(100% - 11px);
                width: 1px;
                background: $border-color;
                content: '';
                top: 13px;
                left: 5px;
            }
        }
    }
    &-status{
        position: relative;
        height: 11px;
        width: 11px;
        border-radius: 50%;
        flex-shrink: 0;
        margin-top: 2px;
        &.is-outline{
            &:after{
                position: absolute;
                height: 7px;
                width: 7px;
                border-radius: 50%;
                background: $white;
                content: '';
                top: 2px;
                left: 2px;
            }
        }
    }
    &-date{
        position: relative;
        color: $base-light;
        width: 90px;
        margin-left: .75rem;
        flex-shrink: 0;
        line-height: 1rem;
        .icon{
            margin-right: 0.25rem;
            vertical-align: middle;
            color: $base-light;
            display: inline-block;
            position: absolute;
            right: 0;
            top: 2px;
        }
    }
    &-data{
        padding-left: 8px;
    }
    &-title{
        font-size: $fx-sz-15;
        color: $base-color;
        margin-bottom: .75rem;
    }
    &-des{
        color: $base-light;
        p{
            margin-bottom: .25rem;
        }
    }
    .time{
        display: block;
        font-size: $fx-sz-12;
        color: $base-light;
    }
}
@include media-breakpoint-up(sm){
    .timeline + .timeline, .timeline-list + .timeline-head{
        margin-top: 2.5rem;
    }
}
@media (max-width:413px){
    .timeline{
        &-item{
            flex-wrap: wrap;
        }
        &-date{
            width: 80px;
        }
        &-data{
            padding: .75rem 0 0 24px;
        }
    }
}